import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutNews = ({
  data: {
    datoCmsText: {
      contentNode: {
        childMarkdownRemark: { html },
      },
    },
  },
}) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div
        css={css`
          width: 90%;
          font-family: Karla;
          font-size: 2rem;
          margin: 0px auto;
        `}
      >
        <p dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    datoCmsText(textid: { eq: "about+news" }) {
      id
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

AboutNews.propTypes = {
  data: PropTypes.shape({
    datoCmsText: PropTypes.shape({
      contentNode: PropTypes.shape({
        childMarkdownRemark: PropTypes.shape({
          html: PropTypes.string,
        }),
      }),
    }),
  }).isRequired,
}

export default AboutNews
